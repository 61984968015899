























































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'Chem51LBProject3OIArgumentation1',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        argumentation1Answer1: null,
        argumentation1Answer2: null,
        argumentation1Answer3: null,
        argumentation1Answer4: null,
      },
      attachments: [] as File[],
      questions1: [
        {
          text1:
            'Traveler. Instructions: Travelers should complete this box during the Argumentation Session. Presenter should complete this box after discussions with your team’s travelers at the end of the Argumentation Session.',
          text2:
            'What were the claims made by other groups? What procedural differences were performed by other groups?',
          inputName: 'argumentation1Answer1',
        },
        {
          text1:
            'Presenter: Presenter should complete this box during the Argumentation Session. Travelers should complete this box after discussions with your team’s travelers at the end of the Argumentation Session.',
          text2:
            'What questions were posed to you that your group hadn’t considered? Does your need to add any additional evidence or justification to your poster?',
          inputName: 'argumentation1Answer2',
        },
      ],
      questions2: [
        {
          text: 'What evidence/justifications from other groups supports your own claim? What evidence/justifications from other groups supports your own claim?',
          inputName: 'argumentation1Answer3',
        },
        {
          text: 'With your group, analyze the data gathered and discussions that you had with other groups and critically evaluate your claim. Do you need to adjust your claim? Regardless of whether your claim has changed, plan and perform an additional analytical test to support your claim with your group.',
          inputName: 'argumentation1Answer4',
        },
      ],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.attachments];
    },
  },
});
